<template>
	<section class="apartment-picker">
		<section
			class="input-wrapper"
			:class="`theme-${theme}`"
			data-cy="selector-floorselection"
		>
			<TnDropdownSelect
				:options="floorOptions.options"
				:dark="dark"
				:size="$store.getters.isMobile ? 's' : 'l'"
				@update:modelValue="(e) => (floorSelection = e)"
				:label="content?.apartmentHelper?.floorSelection?.left || 'Jeg bor i'"
				data-cy="jeg-bor-i"
			>
			</TnDropdownSelect>
			<TnParagraph :color="dark ? 'white' : 'black'">
				{{ content?.apartmentHelper?.floorSelection?.right || "" }}
			</TnParagraph>
		</section>
		<section
			class="input-wrapper"
			:class="`theme-${theme}`"
			data-cy="selector-apartmentselection"
		>
			<TnDropdownSelect
				:disabled="!floorSelection"
				:options="apartmentOptions.options"
				:size="$store.getters.isMobile ? 's' : 'l'"
				:dark="dark"
				@update:modelValue="(e) => (apartmentSelection = e)"
				:label="content?.apartmentHelper?.apartmentSelection?.left || 'I bolignummer'"
				data-cy="bolignummer"
			>
			</TnDropdownSelect>
			<TnParagraph :color="dark ? 'white' : 'black'">
				{{ content?.apartmentHelper?.apartmentSelection.right || "" }}
			</TnParagraph>
		</section>
		<section class="actions">
			<div>
				<TnButton
					class="margin-bottom-xl"
					center
					tertiary
					:class="{ dark: dark }"
					@click="modal = true"
				>
					{{ content?.apartmentHelper?.buttonText || "Hva er et bolignummer?" }}
				</TnButton>
			</div>
			<TnButton
				size="m"
				tertiary
				class="margin-top-s"
				:class="{ dark: dark }"
				@click="$emit('changeComponent', 'addressSearch')"
				data-cy="tilbake"
			>
				Tilbake
			</TnButton>
			<TnButton
				size="m"
				:disabled="!selectedApartment"
				:class="{ dark: dark }"
				@click="$emit('searchOffer', selectedApartment)"
				data-cy="address-search-next"
			>
				{{ content?.apartmentHelper?.nextButtonText || "Se hva jeg kan få" }}
			</TnButton>
		</section>
		<Modal
			v-model="modal"
			width="900"
			:dark="dark"
		>
			<RichText
				:text="content.apartmentHelperlightbox"
				:dark="dark"
			/>
		</Modal>
	</section>
</template>

<script>
import { mapGetters } from "vuex";
export default defineNuxtComponent({
	name: "ApartmentPicker",

	data() {
		return {
			floorSelection: "",
			apartmentSelection: "",
			selectedApartment: null,
			modal: false,
		};
	},

	props: {
		content: { type: Object, default: () => ({}) },
		theme: { type: String, default: "" },
		dark: {
			type: Boolean,
			default: false,
		},
	},

	mounted() {
		this.$track({}, "view", ["leilighet"]);
	},

	watch: {
		apartmentSelection(apartmentNumber) {
			this.selectedApartment = this.apartments.filter((apartment) => apartment.apartmentNumber === apartmentNumber)[0];
		},
	},

	computed: {
		...mapGetters("address", ["apartments"]),

		floorOptions() {
			return {
				options: [...this.apartments]
					.sort((a, b) => parseInt(a.floorNumber) - parseInt(b.floorNumber))
					.reduce(
						(floors, apartment) =>
							floors.includes(`${apartment.floorNumber}. etg`)
								? [...floors]
								: [...floors, `${apartment.floorNumber}. etg`],
						[],
					)
					.map((floors) => ({ label: floors, value: floors })),
			};
		},

		apartmentOptions() {
			return {
				options: [...this.apartments]
					?.filter((apartment) => this.floorSelection?.split(".")?.[0] === apartment?.floorNumber)
					?.map((apartment) => apartment.apartmentNumber)
					?.sort()
					?.map((apartment) => ({ label: apartment, value: apartment })),
			};
		},
	},
});
</script>

<style lang="scss" scoped>
.apartment-picker {
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: flex-start;
	min-width: 0;

	.input-wrapper {
		margin-bottom: 13px;
		min-width: 320px;
		width: 100%;

		&:last-of-type {
			margin-bottom: 20px;
		}

		@include breakpoint(mobile) {
			flex-direction: column;
			align-items: center;

			:deep() {
				.input-select {
					.select-dropdown-menu {
						margin: 0;
					}
				}
			}
		}
	}

	:deep() {
		.actions {
			button.dark:disabled {
				background: $color-neutrals-800-shade;
				border: 2px solid $color-neutrals-800-shade;
			}

			button.tertiary.dark:hover {
				background: hsl(0deg 0% 100% / 10%);
				color: #fff;
				border: 2px solid transparent;
			}
		}
	}

	:deep() {
		.input-select {
			display: flex;
			justify-content: center;

			.select-dropdown-menu {
				margin: 0 20px;

				&.dark {
					.select {
						border: none;
					}
				}

				.select {
					border: 1px solid #cecfd4;
					border-radius: 4px;

					// background: transparent;
					width: 212px;
					height: 40px;
				}
			}
		}
	}
}
</style>
